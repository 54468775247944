import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Alert from '@mui/material/Alert'
import { Fragment, useEffect } from 'react'
import store from './store'
import { TRIGGER_ERROR_CLEAN } from './actions/error'
import { useAppSelector } from './hooks'
import DashboardContainer from './components/dashboardContainer'
import HeaderDashboard from './components/header'
import { Box, margin, padding } from '@mui/system'
import { Grid, Typography } from '@mui/material'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

function App() {
  const err = useAppSelector((state) => state.ERROR['data'])
  const dates = useAppSelector((state) => state.SAVE_DATES['datesIndicator'])

  useEffect(() => {
    setTimeout(() => {
      store.dispatch(TRIGGER_ERROR_CLEAN())
    }, 4000)
  }, [err])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className='App'>
        <header className='App-header'>
          <HeaderDashboard children={<button></button>}></HeaderDashboard>
        </header>
        <Grid 
          paddingTop={'7vw'}
          width={'40vw'}
          container
          spacing={2}
          margin={'auto'}
          columns={8}
        >
            <Grid item xs={2} sm={4} md={4}>
              UTC TIME:
            <Typography fontSize='h8.fontSize' align='center' component='div'>
                {dates.start_UTC}   
              </Typography>
            <Typography fontSize='h8.fontSize' align='center' component='div'>
                {dates.end_UTC}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              LOCAL TIME: 
            <Typography fontSize='h8.fontSize' align='center' component='div'>
                {dates.start_local}   
              </Typography>
            <Typography fontSize='h8.fontSize' align='center' component='div'>
                {dates.end_local}
              </Typography>
            </Grid>
        </Grid>
        <DashboardContainer></DashboardContainer>
        {err !== '' ? (
          <Alert
            variant='outlined'
            severity='error'
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {err}
          </Alert>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </ThemeProvider>
  )
}

export default App
