import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'
const saveDatesCreated = (created: string) => {
  return {
    type: ACTIONS.SAVE_DATES_CREATED,
    payload: { date: created }
  }
}

const saveDatesEnd = (end: string) => {
  return {
    type: ACTIONS.SAVE_DATES_END,
    payload: { date: end }
  }
}

const setDatesOnSearch = (start: string, end: string) => {
  let options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  }

  let optionsUTC: Intl.DateTimeFormatOptions = {
    ...options,
    timeZone: 'UTC'
  }
  const startDate = new Date(parseInt(start))
  const endDate = new Date(parseInt(end))
  return {
    type: ACTIONS.SAVE_DATES_ON_SEARCH,
    payload_dates: {
      start_UTC: startDate.toLocaleString("en-US", optionsUTC),
      end_UTC: endDate.toLocaleString("en-US", optionsUTC),
      start_local: startDate.toLocaleString("en-US", options),
      end_local:endDate.toLocaleString("en-US", options ),
    },
  }
}

export const TRIGGER_SAVE_DATE_CREATED =
  (created: string) => async (dispatch: Dispatch) => {
    dispatch(saveDatesCreated(created))
  }

export const TRIGGER_SAVE_DATE_END =
  (end: string) => async (dispatch: Dispatch) => {
    dispatch(saveDatesEnd(end))
  }

export const SET_DATES_ON_SEARCH =
  (start: string, end: string) => (dispatch: Dispatch) => {
    dispatch(setDatesOnSearch(start, end))
  }
