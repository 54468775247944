import { SECONDS_PER_HOUR } from '../constants/const'
import { Job, StatsResponse } from '../constants/interface'
import { getJobsByDateAll } from './jobsService'

const getSucceeded = (array: Array<Job>) => {
  return array.filter((i) => i.status === 'SUCCEEDED')
}

const getFailed = (array: Array<Job>) => {
  return array.filter((i) => i.status === "FAILED");
};

const convertToGridData = (hours: number, arrayFiltered: Array<Job>) => {
  const succ = getSucceeded(arrayFiltered);
  const fail = getFailed(arrayFiltered);
  const response: StatsResponse = {
    jobsHour: parseFloat((arrayFiltered.length / (hours / 1000)).toFixed(2)),
    numberJobs: arrayFiltered.length,
    percentSucceededJobs:
      succ.length === 0
        ? 0
        : parseFloat((succ.length / arrayFiltered.length).toFixed(2)),
    succeededJobs: succ.length,
    percentFailedJobs:
      fail.length === 0
        ? 0
        : parseFloat((fail.length / arrayFiltered.length).toFixed(2)),
    failedJobs: fail.length,
  };
  return response;
};

const filterByType = (type: string, array: Array<Job>) => {
  return array.filter((x) => x.type === type)
}

const filterByQueue = (queue: string, array: Array<Job>) => {
  return array.filter((x) => x.jobQueue === queue)
}

const getStats = async (createdDate: string, endDate: string, queue: string, type: string) => {
  try {
    const jobsResponse = await getJobsByDateAll(
      {
        createdDate: createdDate,
        endDate: endDate
      },
      true
    )
    let filteredResponse: Array<Job> = jobsResponse.jobs
    if (queue !== 'ALL') {
      filteredResponse = filterByQueue(queue, jobsResponse.jobs)
    }
    if (type !== 'ALL') {
      filteredResponse = filterByType(type, filteredResponse)
    }
    return {
      stats: convertToGridData(
        parseInt(endDate) / SECONDS_PER_HOUR - parseInt(createdDate) / SECONDS_PER_HOUR,
        filteredResponse as Array<Job>
      ),
      total: jobsResponse
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

const getStatsNoLoad = async (createdDate: string, endDate: string, res: Array<Job>, queue: string, type: string) => {
  let filteredResponse: Array<Job> = res
  if (queue !== 'ALL') {
    filteredResponse = filterByQueue(queue, res)
  }
  if (type !== 'ALL') {
    filteredResponse = filterByType(type, filteredResponse)
  }
  return convertToGridData(
    parseInt(endDate) / SECONDS_PER_HOUR - parseInt(createdDate) / SECONDS_PER_HOUR,
    filteredResponse as Array<Job>
  )
}

export { getStats, getStatsNoLoad }
