import { ACTIONS } from '../constants/enum'
import { CostAction } from '../constants/interface'

const initialState = {
  loading: false,
  data: [],
  dataChart: [],
  totalCost: 0
}

export const LIST_COSTS = (state = initialState, action: CostAction) => {
  switch (action.type) {
    case ACTIONS.LIST_COSTS:
      return {
        loading: false,
        error: '',
        data: action.payload
      }
    case ACTIONS.REQUEST_LIST_COSTS:
      return {
        data: [],
        loading: true
      }
    case ACTIONS.ERROR_REQUEST_LIST_COSTS:
      return {
        loading: false,
        data: []
      }
    case ACTIONS.CREATE_CHART_DATA_COST:
      return {
        dataChart: action.payload,
        totalCost: action.total,
        data: state.data
      }
    default:
      return state
  }
}
