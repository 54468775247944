import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Fragment } from 'react'
import { Box } from '@mui/material'

interface Axis {
  x: string
  y: string
  lineName: string
}

interface Props {
  data: Array<any>
  axis: Axis
  floatTotal: boolean
}
const Chart = (props: Props) => {
  const total = props.data.reduce(function (accumulator, curValue) {
    return accumulator + curValue[props.axis.lineName]
  }, 0)

  return (
    <Fragment>
      <Box>{`Total ${props.axis.lineName} : ${props.floatTotal ? parseFloat(total).toFixed(2) :total}`}</Box>
      <ResponsiveContainer aspect={3} width='100%' height='100%'>
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={props.axis.x}
            label={{
              key: 'xAxisLabel',
              value: `${props.axis.x}`,
              position: 'bottom'
            }}
          />
          <YAxis
            label={{
              key: 'yAxisLabel',
              value: `${props.axis.y}`,
              position: 'left',
              angle: -90
            }}
          />
          <Tooltip />
          <Legend align='right' />
          <Line type='monotone' dataKey={props.axis.lineName} stroke='#8884d8' strokeWidth={3} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  )
}

export default Chart
