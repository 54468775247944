import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'

import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Cost, CostOrJob, PropsTable } from '../../constants/interface'
import { DEFAULT_ORDER, DEFAULT_ORDER_BY, EMPTY_COST } from '../../constants/const'
import { FormControlLabel, FormGroup, SelectChangeEvent, Switch } from '@mui/material'
import store from '../../store'
import { SELECT_PAGESTABLE } from '../../actions/pages'
import { useAppSelector } from '../../hooks'
import Chart from '../lineChart'
import { Order, getComparator, stableSort, EnhancedTableHead } from './utils'

const TableCost = ({ headers }: PropsTable) => {
  const [page, setPage] = React.useState(0)
  const rowsPerPage = useAppSelector((state) => state.PAGE_TABLE['pageRow'])
  const [orderBy, setOrderBy] = React.useState<CostOrJob>(DEFAULT_ORDER_BY)
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER)
  const [showChart, setShowChart] = React.useState(false)

  const handleChangeSwitch = (event: SelectChangeEvent) => {
    setShowChart(!showChart)
  }

  const costs = useAppSelector((state) => state.LIST_COSTS['data'])
  const dataChart = useAppSelector((state) => state.LIST_COSTS['dataChart'])

  const visibleRowsCost = React.useMemo(
    () =>
      stableSort(costs, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ) as unknown,
    [order, orderBy, page, rowsPerPage, costs]
  ) as Cost[]

  let elements
  let serverPagination

  elements = (costs as Cost[]).length
  serverPagination = false
  const bodyTable = (
    <TableBody key='cost'>
      {visibleRowsCost.map((row: Cost, index) => (
        <TableRow key={`item${index}`}>
          {Object.keys(EMPTY_COST).map((key, index) => (
            <TableCell key={`key${index}`} style={{ width: 160 }} align='center'>
              {row[key as keyof Cost]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    store.dispatch(SELECT_PAGESTABLE(parseInt(event.target.value, 10)))
    setPage(0)
  }
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: CostOrJob) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={<Switch defaultChecked={showChart} onChange={handleChangeSwitch} />}
          label='Show lineal chart'
        />
      </FormGroup>
      {showChart ? (
        <Chart axis={{ x: 'Time', y: 'Amount ($)', lineName: 'Cost' }} data={dataChart} floatTotal={true}></Chart>
      ) : (
        <TableContainer component={Paper} style={{ maxWidth: '2000px' }}>
          <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headParams={headers} />
            {bodyTable}
            <TableFooter>
              <TableRow key='1'>
                <TablePagination
                  rowsPerPageOptions={[50, 75, 100]}
                  colSpan={headers.length}
                  count={serverPagination ? -1 : elements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  )
}

export default TableCost
