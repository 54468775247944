import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'
import { getStats, getStatsNoLoad } from '../services/statsService'
import { Job, StatsResponse } from '../constants/interface'
import { setError } from './error'
import { getJobsSuccess } from './fetchJobs'
import { paginationCompleted } from './pages'

export const getStatsSuccess = (data: StatsResponse) => {
  return {
    type: ACTIONS.STATS,
    payload: data
  }
}

const getStatsFail = () => {
  return {
    type: ACTIONS.ERROR_REQUEST_STATS
  }
}

const requestStats = () => {
  return {
    type: ACTIONS.REQUEST_STATS
  }
}

export const FETCH_STATS =
  (created: string, end: string, type: string, queue: string, updatedDate: boolean, globalJobs: Array<Job>) =>
  async (dispatch: Dispatch) => {
    dispatch(requestStats())
    try {
      if (updatedDate) {
        dispatch(paginationCompleted(false))
        const statsResponse = await getStats(created, end, queue, type)
        dispatch(getStatsSuccess(statsResponse.stats))
        dispatch(getJobsSuccess(statsResponse.total.jobs))
        dispatch(paginationCompleted(true))
      } else {
        dispatch(getStatsSuccess(await getStatsNoLoad(created, end, globalJobs, queue, type)))
      }
    } catch (e) {
      dispatch(getStatsFail())
      dispatch(setError(JSON.stringify(e)))
    }
  }
