import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Container from '@mui/material/Container'
import * as React from 'react'
import { shallowEqual } from 'react-redux'
import { TAB_TYPE } from '../constants/enum'
import GridStats from './gridStats'
import { headCellsCost, headCellsJobs } from '../constants/const'
import Stack from '@mui/material/Stack'
import { useAppSelector } from '../hooks'
import TableCost from './tables/tableCost'
import TableJob from './tables/tableJob'
import Chart from './lineChart'
const DashboardContainer = () => {
  const tab = useAppSelector((state) => state.SET_TAB['tab'], shallowEqual)

  const loadingJobs = useAppSelector((state) => state.LIST_JOBS['loading'])
  const loadingCosts = useAppSelector((state) => state.LIST_COSTS['loading'])
  const loadingChild = useAppSelector((state) => state.LOAD_CHILD['loading'])
  const dataChilds = useAppSelector((state) => state.LOAD_CHILD['data'])
  const loadingStats = useAppSelector((state) => state.STATS['loading'])
  const paginationJobsCompleted = useAppSelector((state) => state.PAGE_SIZE['loadComplete'])

  const loadingSkeleton = (
    <Stack key={'stack'} display={'flex'} justifyContent={'center'} alignItems={'center'} spacing={1}>
      {[...Array(12)].map((_, index) => (
        <Skeleton key={`skelet ${index}`} variant='rectangular' width={950} height={50} />
      ))}
    </Stack>
  )
  return (
    <React.Fragment>
      <Container>
        <Box>
          {tab === TAB_TYPE.Costs ? (
            loadingCosts ? (
              loadingSkeleton
            ) : (
              <TableCost headers={headCellsCost}></TableCost>
            )
          ) : tab === TAB_TYPE.Jobs ? (
            loadingJobs ? (
              loadingSkeleton
            ) : (
              <TableJob headers={headCellsJobs}></TableJob>
            )
          ) : tab === TAB_TYPE.Stats ? (
            <GridStats showLoading={loadingStats || !paginationJobsCompleted}></GridStats>
          ) : loadingChild ? (
            <Skeleton variant='rectangular' width={1200} height={250} />
          ) : (
            <Chart axis={{ x: 'Time', y: 'Child jobs', lineName: 'Childs' }} data={dataChilds} floatTotal={false}></Chart>
          )}
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default DashboardContainer
