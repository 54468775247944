export enum TAB_TYPE {
  Stats = 'Stats',
  Jobs = 'Jobs',
  Costs = 'Costs',
  Childs = 'ChildJobs'
}

export enum jobType {
  MESHING = 'MESHING',
  JARS_ANIMATION = 'JARS_ANIMATION',
  JARS_RENDER = 'JARS_RENDER',
  COLLIDER = 'COLLIDER'
}

export enum AbscissaHourSeparator {
  TWELVE = 12,
  ONE = 1,
  SIX = 6,
  TWO = 2
}

export enum ACTIONS {
  SET_TAB = 'SET_TAB',
  SET_COSTS = 'SET_COSTS',
  SET_JOBS = 'SET_JOBS',
  SET_CHILD = 'SET_CHILD',
  LOAD_CHILDS = 'LOAD_CHILDS',
  REQUEST_LOAD_CHILDS = 'REQUEST_LOAD_CHILDS',
  ERROR_REQUEST_LOAD_CHILDS = "ERROR_REQUEST_LOAD_CHILDS",
  SET_STATS = 'SET_STATS',
  LIST_JOBS = 'LIST_JOBS',
  LIST_JOBS_CONCAT = 'LIST_JOBS_CONCAT',
  REQUEST_LIST_JOBS = 'REQUEST_LIST_JOBS',
  ERROR_REQUEST_LIST_JOBS = 'ERROR_REQUEST_LIST_JOBS',
  DOWNLOAD_ZIP = 'DOWNLOAD_ZIP',
  CLEAN_DOWNLOAD = 'CLEAN_DOWNLOAD',
  ERROR_REQUEST_DOWNLOAD = 'ERROR_REQUEST_DOWNLOAD',
  LIST_COSTS = 'LIST_COSTS',
  REQUEST_LIST_COSTS = 'REQUEST_LIST_COSTS',
  ERROR_REQUEST_LIST_COSTS = 'ERROR_REQUEST_LIST_COSTS',
  SET_SIZE_PAGE = 'SET_SIZE_PAGE',
  SET_PAGE_TABLE = 'SET_PAGE_TABLE',
  ALL_PAGE_LOAD = 'ALL_PAGE_LOAD',
  STATS = 'STATS',
  REQUEST_STATS = 'REQUEST_STATS',
  ERROR_REQUEST_STATS = 'ERROR_REQUEST_STATS',
  SET_ERROR = 'SET_ERROR',
  CLEAN_ERROR = 'CLEAN_ERROR',
  SAVE_DATES = 'SAVE_DATES',
  CREATE_CHART_DATA_COST = 'CREATE_CHART_DATA_COST',
  CREATE_CHART_DATA_STAT = 'CREATE_CHART_DATA_STAT',
  SAVE_DATES_CREATED = 'SAVE_DATES_CREATED',
  SAVE_DATES_END = 'SAVE_DATES_END',
  SAVE_DATES_ON_SEARCH = "SAVE_DATES_ON_SEARCH",
}
