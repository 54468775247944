import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'
import { dataCost, getCosts } from '../services/costService'
import { ChartData, Cost } from '../constants/interface'
import { setError } from './error'

const getCostsSuccess = (data: Array<Cost>) => {
  return {
    type: ACTIONS.LIST_COSTS,
    payload: data
  }
}

const getCostsFail = () => {
  return {
    type: ACTIONS.ERROR_REQUEST_LIST_COSTS
  }
}

const requestCosts = () => {
  return {
    type: ACTIONS.REQUEST_LIST_COSTS
  }
}

const processtData = (data: Array<ChartData>) => {
  const sum = 0
  return {
    type: ACTIONS.CREATE_CHART_DATA_COST,
    payload: data,
    total: data.reduce(function (accumulator, curValue) {
      return accumulator + curValue.Cost
    }, sum)
  }
}

export const FETCH_COSTS = (created: string, end: string) => async (dispatch: Dispatch) => {
  dispatch(requestCosts())
  try {
    const costs = await getCosts(created, end)
    dispatch(getCostsSuccess(costs))
    dispatch(processtData(dataCost(costs)))
  } catch (e) {
    dispatch(getCostsFail())
    dispatch(setError(JSON.stringify(e)))
  }
}
