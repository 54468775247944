import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'

export const setError = (err: string) => {
  return {
    type: ACTIONS.SET_ERROR,
    payload: err
  }
}

const cleanError = () => {
  return {
    type: ACTIONS.CLEAN_ERROR
  }
}

export const TRIGGER_ERROR = (err: string) => (dispatch: Dispatch) => {
  dispatch(setError(err))
}

export const TRIGGER_ERROR_CLEAN = () => (dispatch: Dispatch) => {
  dispatch(cleanError())
}
