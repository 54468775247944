import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'

import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Job, CostOrJob, PropsTable } from '../../constants/interface'
import { DEFAULT_ORDER, DEFAULT_ORDER_BY, EMPTY_JOB } from '../../constants/const'
import store from '../../store'

import { SELECT_PAGESTABLE } from '../../actions/pages'
import { useAppSelector } from '../../hooks'
import { Order, getComparator, stableSort, EnhancedTableHead } from './utils'
import { CLEAN_DOWNLOAD, DOWNLOAD } from '../../actions/downloadOutput'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';

const TableJob = ({ headers }: PropsTable) => {
  const [page, setPage] = React.useState(0)
  const rowsPerPage = useAppSelector((state) => state.PAGE_TABLE['pageRow'])
  const [orderBy, setOrderBy] = React.useState<CostOrJob>(DEFAULT_ORDER_BY)
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER)
  const download = useAppSelector((state) => state.DOWNLOAD_ZIP['data'])

  const startDownload = (id: string) => {
    store.dispatch(DOWNLOAD(id))
  }

  if (download !== '' && download !== undefined) {
    const aTag = document.createElement('a')
    aTag.href = download
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
    store.dispatch(CLEAN_DOWNLOAD())
  }

  const loadComplete = useAppSelector((state) => state.PAGE_SIZE['loadComplete'])

  const jobs = useAppSelector((state) => state.LIST_JOBS['data'])

  const visibleRowsJob = React.useMemo(
    () =>
      stableSort(jobs, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ) as unknown,
    [order, orderBy, page, rowsPerPage, jobs]
  ) as Job[]

  const bodyTable = (
    <TableBody key="job">
      {visibleRowsJob.map((row: Job, index) => (
        <TableRow key={`item${index}`}>
          {Object.keys(EMPTY_JOB).map((key, index) =>
            key === "output" ? (
              <TableCell
                key={`key${index}`}
                style={{ width: 160 }}
                align="left"
              >
                {row.status !== "SUCCEEDED" ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <CloudDownloadIcon
                    key={`icon${index}`}
                    onClick={(e) => startDownload(row.masterjob)}
                    color="primary"
                    style={{ cursor: "pointer" }}
                  ></CloudDownloadIcon>
                )}
              </TableCell>
            ) : key === "masterjob" ? (
              <Tooltip key={`tooltip${index}`} title={row.masterjob}>
                <TableCell
                  key={`key${index}`}
                  style={{ width: 160 }}
                  align="left"
                >
                  {row[key as keyof Job]?.substring(0, 8) + "..."}
                </TableCell>
              </Tooltip>
            ) : key === "status" ? (
              <TableCell
                key={`key${index}`}
                style={{ width: 160 }}
                align="left"
              >
                <div
                  style={
                    row.status === "FAILED"
                      ? { color: "red" }
                      : row.status === "SUCCEEDED"
                      ? { color: "#90EE90" }
                      : { color: "white" }
                  }
                >
                  {row[key as keyof Job]}
                </div>
              </TableCell>
            ) : (
              <TableCell
                key={`key${index}`}
                style={{ width: 160 }}
                align="left"
              >
                {row[key as keyof Job]}
              </TableCell>
            )
          )}
        </TableRow>
      ))}
    </TableBody>
  );


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    store.dispatch(SELECT_PAGESTABLE(parseInt(event.target.value, 10)))
    setPage(0)
  }
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: CostOrJob) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{ maxWidth: '2000px' }}>
        <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headParams={headers} />
          {bodyTable}
          <TableFooter>
            <TableRow key='1'>
              <TablePagination
                rowsPerPageOptions={[50, 75, 100]}
                colSpan={headers.length}
                count={!loadComplete && visibleRowsJob.length !== 0 ? -1 : (jobs as Array<Job>).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default TableJob
