import { ACTIONS } from '../constants/enum'

export const SELECT_PAGESTABLE = (size: number) => {
  return {
    type: ACTIONS.SET_PAGE_TABLE,
    payload: size
  }
}

export const paginationCompleted = (loaded: boolean) => {
  return {
    type: ACTIONS.ALL_PAGE_LOAD,
    payload: loaded
  }
}
