import { ONE_DAY } from '../constants/const'
import { ACTIONS } from '../constants/enum'
import { SaveDateActions } from '../constants/interface'
const today = new Date()
today.setSeconds(0,0)
const initialState = {
  createdDate: (today.getTime() - ONE_DAY).toString(),
  endDate: today.getTime().toString(),
  datesIndicator: {
    start_UTC: "",
    end_UTC: "",
    start_local: "",
    end_local: "",
  },
}
export const SAVE_DATES = (
  state = initialState,
  action: SaveDateActions
) => {
  switch (action.type) {
    case ACTIONS.SAVE_DATES_CREATED:
      return {
        ...state,
        createdDate: action.payload.date,
        endDate: state.endDate,
      }
    case ACTIONS.SAVE_DATES_END:
      return {
        ...state,
        createdDate: state.createdDate,
        endDate: action.payload.date,
      }
    case ACTIONS.SAVE_DATES_ON_SEARCH:
      return {
        ...state,
        datesIndicator: action.payload_dates,
      }
    default:
      return state
  }
}
