import { Box, TextField, Button, Alert } from '@mui/material'
import * as React from 'react'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { JOB_TYPES, ONE_WEEK, QUEUES, SIX_MONTH } from '../constants/const'
import { TAB_TYPE } from '../constants/enum'
import { FETCH_DATA_JOBS, FETCH_DATA_JOB, FETCH_DATA_JOB_CHILD } from '../actions/fetchJobs'
import store from '../store'
import { FETCH_STATS } from '../actions/fetchStats'
import { FETCH_COSTS } from '../actions/fetchCosts'
import { ChangeEvent } from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { TRIGGER_ERROR } from '../actions/error'
import { useAppSelector } from '../hooks'
import dayjs, { Dayjs } from 'dayjs'
import {
  TRIGGER_SAVE_DATE_END,
  TRIGGER_SAVE_DATE_CREATED,
  SET_DATES_ON_SEARCH,
} from '../actions/date'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

export const addZeroBefore = (char: string) => {
  return char.length <= 1 ? '0' + char : char
}

function renderItemsType(array: string[]) {
  if (array.length > 0) {
    return array.map((i) =>
      i !== '' ? (
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      ) : (
        <MenuItem key={i} value=''>
          <em>All</em>
        </MenuItem>
      )
    )
  } else {
    return (
      <MenuItem key='' value=''>
        <em>All</em>
      </MenuItem>
    )
  }
}

interface PropsFilter {
  tab: string
}

const validationDates = (created: string, end: string) => {
  if (created === "" || end === "") return "Error: Empty field date";
  else if (created === "NaN" || end === "NaN") return "Error: Date not valid";
  else if (end <= created) return "Error: EndDate before CreatedDate";
  return "";
};

const validationChildDates = (created: string, end: string) => {
  if ((new Date(parseInt(end)).getTime() - new Date(parseInt(created)).getTime()) > ONE_WEEK) {
    return "Error: More than 7 days is not allowed"
  }
  return validationDates(created, end)
};

const FormFilters = (props: PropsFilter) => {
  const [textType, setTextType] = React.useState('ALL')
  const [textQueue, setTextQueue] = React.useState('ALL')
  const [textMasterJob, setTextMasterJob] = React.useState('')
  const [showTextMasterJob, setShowTextMasterJob] = React.useState(false)

  const dates = useAppSelector((state) => state.SAVE_DATES)
  const createdDateCommon = dates['createdDate']
  const endDateCommon = dates['endDate']
  const [FormCreatedDate, setFormCreatedDate] = React.useState(createdDateCommon)
  const [FormEndDate, setFormEndDate] = React.useState(endDateCommon)
  const jobsResult = useAppSelector((state) => state.LIST_JOBS)

  const handleChangeType = (event: SelectChangeEvent) => {
    setTextType(event.target.value)
  }

  const handleChangeQueue = (event: SelectChangeEvent) => {
    setTextQueue(event.target.value)
  }

  const handleChangeSwitch = (event: SelectChangeEvent) => {
    setShowTextMasterJob(!showTextMasterJob)
  }
  const commonDateField =
    props.tab === TAB_TYPE.Jobs && showTextMasterJob ? (
      <TextField
        id='text-job'
        label='Master Job'
        variant='outlined'
        onChange={(text: ChangeEvent<HTMLInputElement>) => {
          setTextMasterJob(text.target.value)
        }}
      />
    ) : (
      <LocalizationProvider key={'common'} dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label='Created Date'
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          onChange={(date) => {
            const dateString = date as Dayjs
            setFormCreatedDate(dateString.toDate().getTime().toString())
          }}
          defaultValue={dayjs(new Date(parseInt(createdDateCommon)))}
        />
        <DateTimePicker
          label='End Date'
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          onChange={(date) => {
            const dateString = date as Dayjs
            setFormEndDate(dateString.toDate().getTime().toString())
          }}
          defaultValue={dayjs(new Date(parseInt(endDateCommon)))}
        />
      </LocalizationProvider>
    )

  let textFields
  if (props.tab === TAB_TYPE.Jobs) {
    textFields = (
      <React.Fragment>
        <FormGroup>
          <FormControlLabel
            control={<Switch defaultChecked={showTextMasterJob} onChange={handleChangeSwitch} />}
            label='Query job by master ID'
          />
        </FormGroup>{' '}
      </React.Fragment>
    )
  } else if (props.tab === TAB_TYPE.Stats) {
    textFields = (
      <React.Fragment>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id='demo-simple-select-helper-label'>Type Job</InputLabel>
          <Select variant='outlined' value={textType} label='Type Job' onChange={handleChangeType}>
            {renderItemsType(JOB_TYPES)}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id='demo-simple-select-helper-label'>Queue</InputLabel>
          <Select variant='outlined' value={textQueue} label='Queue' onChange={handleChangeQueue}>
            {renderItemsType(QUEUES)}
          </Select>
        </FormControl>
      </React.Fragment>
    )
  } else if (props.tab === TAB_TYPE.Costs) {
    textFields = (
      <Alert severity="info" variant="outlined">
        COSTS tab ignores <strong>TIME</strong> and reports complete <strong>DAYS</strong> 
      </Alert>
    );
  }

  const startRequest = () => {
    if (showTextMasterJob && props.tab === TAB_TYPE.Jobs) {
      if (textMasterJob === "") {
        store.dispatch(TRIGGER_ERROR("Error: masterjob ID is not filled"))
        return
      }
    } else if (props.tab === TAB_TYPE.Childs) {
      const validChildDate = validationChildDates(FormCreatedDate, FormEndDate)
      if (validChildDate !== "") {
        store.dispatch(TRIGGER_ERROR(validChildDate))
        return
      }
    } else {
      const checkDate = validationDates(FormCreatedDate, FormEndDate)
      if (checkDate !== "") {
        store.dispatch(TRIGGER_ERROR(checkDate))
        return
      }
    }

    store.dispatch(SET_DATES_ON_SEARCH(FormCreatedDate, FormEndDate))
    const updated = FormCreatedDate !== createdDateCommon || FormEndDate !== endDateCommon
    if (updated && (props.tab === TAB_TYPE.Stats || props.tab === TAB_TYPE.Jobs)) {
      store.dispatch(TRIGGER_SAVE_DATE_CREATED(FormCreatedDate))
      store.dispatch(TRIGGER_SAVE_DATE_END(FormEndDate))
    }
    if (props.tab === TAB_TYPE.Jobs) {
      if (textMasterJob !== '') {
        store.dispatch(FETCH_DATA_JOB(textMasterJob))
        setTextMasterJob('')
      } else {
        store.dispatch(FETCH_DATA_JOBS(FormCreatedDate, FormEndDate, updated, jobsResult['data']))
      }
    } else if (props.tab === TAB_TYPE.Costs) {
      store.dispatch(FETCH_COSTS(formatDate(FormCreatedDate), formatDate(FormEndDate)))
    } else if (props.tab === TAB_TYPE.Stats) {
      store.dispatch(FETCH_STATS(FormCreatedDate, FormEndDate, textType, textQueue, updated, jobsResult['data']))
    } else if (props.tab === TAB_TYPE.Childs) {
      store.dispatch(FETCH_DATA_JOB_CHILD(FormCreatedDate, FormEndDate))
    }
  }

  const formatDate = (unix: string) => {
    const c = new Date(parseInt(unix)).toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
    const split = c.split('/')
    return addZeroBefore(split[2]) + '-' + addZeroBefore(split[0]) + '-' + addZeroBefore(split[1])
  }

  const returnFields = (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} width={'100%'} maxWidth={'1200px'}>
        {commonDateField}
        {textFields}
        <Button onClick={startRequest} size='medium' variant='contained'>
          Search
        </Button>
      </Box>
    </React.Fragment>
  )

  return returnFields
}

export { FormFilters }
