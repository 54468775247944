import { ACTIONS } from '../constants/enum'
import { ChildJobsActions, JobsAction } from '../constants/interface'

const initialState = {
  loading: false,
  data: []
}

export const LIST_JOBS = (state = initialState, action: JobsAction) => {
  switch (action.type) {
    case ACTIONS.LIST_JOBS:
      return {
        loading: false,
        data: action.payload
      }
    case ACTIONS.REQUEST_LIST_JOBS:
      return {
        loading: true
      }
    case ACTIONS.ERROR_REQUEST_LIST_JOBS:
      return {
        loading: false,
        data: []
      }
    case ACTIONS.LIST_JOBS_CONCAT:
      return {
        data: [...state.data, ...action.payload]
      }
    default:
      return state
  }
}

export const LOAD_CHILD = (state = { loading: false, data: [] }, action: ChildJobsActions) => {
  switch (action.type) {
    case ACTIONS.LOAD_CHILDS:
      return {
        loading: false,
        data: action.payload
      }
    case ACTIONS.REQUEST_LOAD_CHILDS:
      return {
        loading: true
      }
    case ACTIONS.ERROR_REQUEST_LOAD_CHILDS:
      return {
        loading: false,
        data: []
      }
    default:
      return state
  }
}
