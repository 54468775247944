import { SERVICES_URL, ENV, ONE_MONTH, ONE_YEAR } from '../constants/const'
import axios from 'axios'
import { ChartData, Cost } from '../constants/interface'

const env = ENV

const ConvertDateDays = (created: string) => {
  const date = new Date(created)
  const month = date.toLocaleString('default', { month: 'long' })
  return month + ' ' + date.getDate()
}

const getMyMonth = (date: string): string => {
  return date.split('-')[1]
}

const ConvertDateMonths = (arrayCosts: Array<Cost>): Array<ChartData> => {
  const arrayResponse: Array<ChartData> = []
  let previousMonth = getMyMonth(arrayCosts[0].TimePeriodStart)
  let monthAmount = arrayCosts[0].Amount
  arrayCosts.slice(1, arrayCosts.length).forEach((ac, index) => {
    if (previousMonth !== getMyMonth(ac.TimePeriodStart)) {
      const date = new Date(arrayCosts[index - 1].TimePeriodStart)
      const month = date.toLocaleString('default', { month: 'long' })
      arrayResponse.push({
        Cost: monthAmount,
        Time: month
      })
      previousMonth = getMyMonth(ac.TimePeriodStart)
      monthAmount = ac.Amount
      if (index === arrayCosts.length - 2) {
        const date = new Date(ac.TimePeriodStart)
        const month = date.toLocaleString('default', { month: 'long' })

        arrayResponse.push({
          Cost: monthAmount,
          Time: month
        })
        return
      }
    } else if (index === arrayCosts.length - 2) {
      const date = new Date(ac.TimePeriodStart)
      const month = date.toLocaleString('default', { month: 'long' })
      arrayResponse.push({
        Cost: monthAmount + ac.Amount,
        Time: month
      })
    } else {
      monthAmount += ac.Amount
    }
  })

  return arrayResponse
}

const decimalsConvert = (costs: Array<Cost>) => {
  const res = costs.map((c) => {
    const container: Cost = {
      TimePeriodEnd: '',
      TimePeriodStart: '',
      Amount: 0
    }

    container.TimePeriodStart = c.TimePeriodStart
    container.TimePeriodEnd = c.TimePeriodEnd
    container.Amount = parseFloat(c.Amount.toFixed(2))

    return container
  })
  return res
}

const getCosts = async (createdDate: string, endDate: string) => {
  try {
    const request = await axios({
      method: 'post',
      url: `${SERVICES_URL[env]}/costs`,
      data: { startDate: createdDate, endDate: endDate }
    })

    return decimalsConvert(request.data.costArray)
  } catch (e) {
    console.error(e)
    throw e
  }
}

const dataCost = (costs: Array<Cost>): Array<ChartData> => {
  let response: Array<ChartData> = []
  if (costs.length <= ONE_MONTH) {
    costs.forEach((c) =>
      response.push({
        Cost: c.Amount,
        Time: ConvertDateDays(c.TimePeriodStart)
      })
    )
  } else if (costs.length > ONE_MONTH && costs.length <= ONE_YEAR) {
    response = ConvertDateMonths(costs)
  } else {
    throw new Error('No more than 1 year accepted for chart')
  }
  return response
}

export { getCosts, dataCost }
