import axios from 'axios'
import { JOB_BY_ID, JOB_OUTPUT, GET_JOBS_TYPE } from './queries'
import { ChildChart, ChildJob, DateItem, Job, JobResponse, LastKeyType, QueryJobParams } from '../constants/interface'
import { ENV, SECONDS_PER_HOUR, SERVICES_URL } from '../constants/const'
import { addZeroBefore } from '../components/filters'
import { AbscissaHourSeparator } from '../constants/enum'
const env = ENV
const MILISECONDS_TO_DAYS = SECONDS_PER_HOUR * 1000 * 24
const yearFirst = (date: string) => {
  const arrayDate = date.split('/')
  const arrayTime = arrayDate[2].split(',')
  const month = arrayDate[1].length < 2 ? '0' + arrayDate[1] : arrayDate[1]
  const day = arrayDate[0].length < 2 ? '0' + arrayDate[0] : arrayDate[0]

  return arrayTime[0] + '/' + month + '/' + day + arrayTime[1]
}

const formatDates = (arrayJobs: Array<Job>): Array<Job> => {
  arrayJobs.forEach((job, index) => {
    arrayJobs[index].createdAt = yearFirst(new Date(parseInt(job.createdAt !== null ? job.createdAt : "000000000")).toLocaleString('en-GB'))
    arrayJobs[index].stoppedAt = yearFirst(new Date(parseInt(job.stoppedAt !== null ? job.stoppedAt : "000000000")).toLocaleString('en-GB'))
  })
  return arrayJobs
}

const getJobByID = async (masterjob: string, returnChilds: boolean): Promise<JobResponse> => {
  const data = JSON.stringify({
    query: JOB_BY_ID,
    variables: {
      masterjob: masterjob
    }
  })

  const req = {
    method: 'post',
    url: SERVICES_URL[env] + '/graphql',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  }
  try {
    const { data } = await axios(req)
    const arrayJob = data.data.getAutomationById
    const found = returnChilds ? arrayJob : arrayJob.find((element: Job) => element.jobId === 'n/a')
    return found === undefined ? { jobs: [] } : { jobs: returnChilds ? [found] : formatDates([found]) }
  } catch (e) {
    console.error(e)
    throw e
  }
}

const getJobOutput = async (materjob: string) => {
  const data = JSON.stringify({
    query: JOB_OUTPUT,
    variables: {
      masterjob: materjob
    }
  })

  const req = {
    method: 'post',
    url: SERVICES_URL[env] + '/graphql',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  }
  try {
    const response = await axios(req)
    return response.data.data.getJobOutput[0]
  } catch (e) {
    console.error(e)
    throw e
  }
}

interface LooseObject {
  [key: string]: number
}

const ConvertDateDaysHourObject = (separator: number, dates: DateItem[], min: Date, max: Date) => {
  const statsDates: LooseObject = {}
  for (
    let initial = min.getTime(), counter = 1, auxMax = new Date(initial + separator * 60 * 1000);
    initial < max.getTime();
    counter++, initial += separator * counter * 60 * 1000, auxMax = new Date(initial + separator * counter * 60 * 1000)
  ) {
    const auxDates = dates.filter((cd) => auxMax > cd.date && cd.date > new Date(initial))
    statsDates[
      addZeroBefore(auxMax.getDate().toString()) +
        addZeroBefore(auxMax.toLocaleString('default', { month: 'long' })) +
        '-' +
        addZeroBefore(auxMax.getHours().toString()) +
        'h'
    ] = auxDates.reduce(function (accumulator, curValue) {
      return accumulator + curValue.number
    }, 0)
  }
  return statsDates
}

const getTimeInterval = (max: number, min: number) => {
  const days = (max - min) / MILISECONDS_TO_DAYS
  if (days >= 6 && days <= 7) {
    return AbscissaHourSeparator.TWELVE
  } else if (days >= 4 && days < 6) {
    return AbscissaHourSeparator.SIX
  } else if (days >= 2 && days < 4) {
    return AbscissaHourSeparator.TWO
  } else if (days >= 0 && days < 2) return AbscissaHourSeparator.ONE
  else throw new Error('Not correct date interval')
}

function myFunction(child: ChildJob) {
  return { date: new Date(parseInt(child.createdAt)), number: child.number }
}

const processChilds = (childs: Array<ChildJob>) => {
  const childDates: DateItem[] = childs.map(myFunction)
  const maxDate = childDates.reduce(function (a, b) {
    return a.date > b.date ? a : b
  })
  const minDate = childDates.reduce(function (a, b) {
    return a.date < b.date ? a : b
  })
  const hoursInterval = getTimeInterval(maxDate.date.getTime(), minDate.date.getTime())
  return ConvertDateDaysHourObject(hoursInterval, childDates, minDate.date, maxDate.date)
}

const convertChildsData = (childs: Array<ChildJob>) => {
  const response: Array<ChildChart> = []
  const processed: LooseObject = processChilds(childs)
  for (const property in processed) {
    response.push({ Time: property, Childs: processed[property] })
  }
  return response
}

const getChilds = async (created: string, end: string): Promise<Array<ChildChart>> => {
  const childs: Array<Promise<JobResponse>> = []
  try {
    const masterJobs = await getJobsByDateAll({ createdDate: created, endDate: end }, true)
    masterJobs.jobs.forEach((job) => childs.push(getJobByID(job.masterjob, true)))
    const allChilds = await Promise.all(childs)
    const chart: Array<ChildJob> = []
    allChilds.forEach((child: any) => {
      chart.push({
        createdAt: child.jobs[0][0].createdAt,
        number: child.jobs[0].length - 1
      })
    })
    return convertChildsData(chart)
  } catch (e) {
    console.error(e)
    throw e
  }
}

const recursive = async (
  createdAt: string,
  stoppedAt: string,
  type: string,
  lastKey?: LastKeyType
): Promise<Array<Job>> => {
  if (lastKey === null) return []
  const data = JSON.stringify({
    query: GET_JOBS_TYPE,
    variables: {
      startDate: createdAt,
      endDate: stoppedAt,
      type: type,
      pageSize: 300,
      lastKey: lastKey
    }
  })

  const req = {
    method: 'post',
    url: SERVICES_URL[env] + '/graphql',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  }

  try {
    const { data } = await axios(req)
    const recursiveResponse = await recursive(
      createdAt,
      stoppedAt,
      type,
      data.data.getAutomationByTypeDateRangeLastKey.lastKey
    )
    return [...formatDates(data.data.getAutomationByTypeDateRangeLastKey.jobs), ...recursiveResponse]
  } catch (e) {
    console.error(e)
    throw e
  }
}
const getJobsByDateAll = async (JobParams: QueryJobParams, recursiveVal: boolean): Promise<JobResponse> => {
  const types = ['MESHING', 'JARS_ANIMATION', 'COLLIDER', 'JARS_RENDER']
  const arr: any = []
  if (recursiveVal) {
    types.forEach((type, index) =>
      arr.push(
        recursive(
          JobParams.createdDate,
          JobParams.endDate,
          type,
          JobParams.lastKeys ? JobParams.lastKeys[index] : undefined
        )
      )
    )
    const response = await Promise.all(arr)
    return { jobs: response.flat() }
  } else {
    types.forEach((type) => {
      const data = JSON.stringify({
        query: GET_JOBS_TYPE,
        variables: {
          startDate: JobParams.createdDate,
          endDate: JobParams.endDate,
          type: type,
          pageSize: 250
        }
      })

      const req = {
        method: 'post',
        url: SERVICES_URL[env] + '/graphql',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      arr.push(axios(req))
    })

    try {
      const response = await Promise.all(arr)
      const jobsResponse = response.flat()
      const jobs = jobsResponse.reduce(function (accumulator, curValue) {
        return accumulator.concat(curValue.data.data.getAutomationByTypeDateRangeLastKey.jobs)
      }, [])
      const lks = jobsResponse.reduce(function (accumulator, curValue) {
        return accumulator.concat(curValue.data.data.getAutomationByTypeDateRangeLastKey.lastKey)
      }, [])
      return { jobs: formatDates(jobs), lastKeys: lks }
    } catch (e) {
      console.error(e)
      throw e
    }
  }
}

export { getJobByID, getJobOutput, getChilds, getJobsByDateAll }
