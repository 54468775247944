export const JOB_BY_ID = `query Query($masterjob: String!) {
  getAutomationById(masterjob: $masterjob) {
    createdAt
    masterjob
    status
    stoppedAt
    type
    jobQueue
    jobId
  }
}`

export const JOB_OUTPUT = `
query Query($masterjob: String!) {
  getJobOutput(masterjob: $masterjob)
}`

export const GET_JOBS_TYPE = `
query GetAutomationByTypeDateRangeLastKey($type: jobType!, $startDate: String!, $endDate: String!, $pageSize: Int!, $lastKey: LastKeyType) {
  getAutomationByTypeDateRangeLastKey(type: $type, startDate: $startDate, endDate: $endDate, pageSize: $pageSize, lastKey: $lastKey) {
    jobs {
      masterjob
      jobQueue
      stoppedAt
      status
      type
      createdAt
    }
    lastKey {
      createdAt
      masterjob
      type
    }
  }
}
`
