import { HeadCell, HeadCellJob } from './interface'

export const SERVICES_URL = {
  dev: 'https://api.atlas-dev.gemvision.com',
  prod: 'https://api.atlas.gemvision.com',
  local: 'http://localhost:4000'
}

export const ENV = process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'dev'

export const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

export const SIX_MONTH = 180 * 24 * 60 * 60 * 1000

export const ONE_DAY = 24 * 3600 * 1000

export const ONE_MONTH = 31

export const ONE_YEAR = 365

export const SECONDS_PER_HOUR = 60 * 60

export const JOB_TYPES = ['ALL', 'MESHING', 'COLLIDER', 'JARS_RENDER', 'JARS_ANIMATION']

export const QUEUES = [
  "ALL",
  "animation-queue-V4",
  "collider-queue-V4",
  "generic-queue-V4",
  "onpremrender-queue-V4",
  "onpremanimation-queue-V4",
  "catalog-queue-V4",
  "meshing-queue-V4"
];

export const STATS_KEYS = [
  { key: "jobsHour", label: "Jobs per hour" },
  { key: "numberJobs", label: "Number of jobs" },
  { key: "succeededJobs", label: "Number of succeeded jobs" },
  { key: "percentSucceededJobs", label: "% of succeeded jobs" },
  { key: "failedJobs", label: "Number of failed jobs" },
  { key: "percentFailedJobs", label: "% of failed jobs" },
];


export const EMPTY_JOB = {
  status: '',
  type: '',
  masterjob: '',
  createdAt: '',
  stoppedAt: '',
  output: '',
  jobQueue: ''
}

export const EMPTY_COST = {
  TimePeriodStart: '',
  TimePeriodEnd: '',
  Amount: ''
}

export const headCellsCost: HeadCell[] = [
  {
    id: 'TimePeriodStart',
    numeric: false,
    label: 'Created Date'
  },
  {
    id: 'TimePeriodEnd',
    numeric: false,
    label: 'End Date'
  },
  {
    id: 'Amount',
    numeric: false,
    label: 'Amount ($)'
  }
]

export const headCellsJobs: HeadCellJob[] = [
  {
    id: 'status',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type'
  },
  {
    id: 'masterjob',
    numeric: false,
    label: 'Masterjob'
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Created Date (YYYY/MM/DD)'
  },
  {
    id: 'stoppedAt',
    numeric: false,
    label: 'End Date (YYYY/MM/DD)'
  },
  {
    id: 'output',
    numeric: false,
    label: 'Output'
  },
  {
    id: 'jobQueue',
    numeric: false,
    label: 'jobQueue'
  }
]

export const headerHeight = '5vw'
export const headerTitle = 'ATLAS DASHBOARD'

export const DEFAULT_ORDER = 'asc'
export const DEFAULT_ORDER_BY = 'Amount'
