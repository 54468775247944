import { ACTIONS, TAB_TYPE } from '../constants/enum'
import { Dispatch } from 'redux'

const SET_TAB_COST = () => {
  return {
    type: ACTIONS.SET_COSTS,
    payload: TAB_TYPE.Costs
  }
}

const SET_TAB_JOB = () => {
  return {
    type: ACTIONS.SET_JOBS,
    payload: TAB_TYPE.Jobs
  }
}

const SET_TAB_STATS = () => {
  return {
    type: ACTIONS.SET_STATS,
    payload: TAB_TYPE.Stats
  }
}

const SET_TAB_CHILD = () => {
  return {
    type: ACTIONS.SET_CHILD,
    payload: TAB_TYPE.Childs
  }
}

export const SELECT_TAB = (actionTabType: TAB_TYPE) => (dispatch: Dispatch) => {
  if (actionTabType === TAB_TYPE.Costs) {
    dispatch(SET_TAB_COST())
  } else if (actionTabType === TAB_TYPE.Jobs) {
    dispatch(SET_TAB_JOB())
  } else if (actionTabType === TAB_TYPE.Stats) {
    dispatch(SET_TAB_STATS())
  } else {
    dispatch(SET_TAB_CHILD())
  }
  return
}
