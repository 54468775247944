import { ACTIONS } from '../constants/enum'
import { StatAction, StatsResponse } from '../constants/interface'

const initialData: StatsResponse = {
  jobsHour: 0,
  numberJobs: 0,
  percentSucceededJobs: 0,
  succeededJobs: 0,
  percentFailedJobs: 0,
  failedJobs: 0,
};

const initialState = {
  loading: false,
  data: initialData,
  chart: []
}

const STATS = (state = initialState, action: StatAction) => {
  switch (action.type) {
    case ACTIONS.STATS:
      return {
        loading: false,
        data: action.payload
      }
    case ACTIONS.REQUEST_STATS:
      return {
        loading: true
      }
    case ACTIONS.ERROR_REQUEST_STATS:
      return {
        loading: false,
        data: initialData
      }
    case ACTIONS.CREATE_CHART_DATA_STAT:
      return {
        data: state.data
      }
    default:
      return state
  }
}

export default STATS
