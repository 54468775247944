import * as React from 'react'

import Paper from '@mui/material/Paper'
import { experimentalStyled as styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { STATS_KEYS } from '../constants/const'
import InputLabel from '@mui/material/InputLabel'
import Skeleton from '@mui/material/Skeleton'
import { useAppSelector } from '../hooks'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

interface Props {
  showLoading: boolean
}

const GridStats = (props: Props) => {
  const data = useAppSelector((state) => state.STATS['data'])

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 8 }}>
      {STATS_KEYS.map((key) => (
        <Grid item xs={2} sm={4} md={4} key={key.key}>
          {props.showLoading ? (
            <Skeleton variant='rectangular' width={550} height={50} />
          ) : (
            <React.Fragment>
              <InputLabel id='key'>{key.label}</InputLabel>
              <Item>{data[key.key]}</Item>
            </React.Fragment>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default GridStats
