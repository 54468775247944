import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'
import { getChilds, getJobByID, getJobsByDateAll } from '../services/jobsService'
import { ChildChart, Job } from '../constants/interface'
import { setError } from './error'
import { paginationCompleted } from './pages'
import { getStatsSuccess } from "./fetchStats";
import { getStatsNoLoad } from '../services/statsService'

export const getJobsSuccess = (data: Array<Job>) => {
  return {
    type: ACTIONS.LIST_JOBS,
    payload: data
  }
}

const getChildsJobSuccess = (data: Array<ChildChart>) => {
  return {
    type: ACTIONS.LOAD_CHILDS,
    payload: data
  }
}

const getJobsFail = () => {
  return {
    type: ACTIONS.ERROR_REQUEST_LIST_JOBS
  }
}

const requestJobs = () => {
  return {
    type: ACTIONS.REQUEST_LIST_JOBS
  }
}

const requestChildJobs = () => {
  return {
    type: ACTIONS.REQUEST_LOAD_CHILDS
  }
}

const errorRequestChildJobs = () => {
  return {
    type: ACTIONS.ERROR_REQUEST_LOAD_CHILDS
  }
}

const concatSuccessJob = (jobs: Array<Job>) => {
  return {
    type: ACTIONS.LIST_JOBS_CONCAT,
    payload: jobs
  }
}

export const FETCH_DATA_JOBS =
  (created: string, end: string, updated: boolean, globalJobs: Array<Job>) => async (dispatch: Dispatch) => {
    dispatch(requestJobs())
    try {
      if (updated || globalJobs.length <= 1) {
        dispatch(paginationCompleted(false))
        const jobsResponse = await getJobsByDateAll(
          {
            createdDate: created,
            endDate: end
          },
          false
        )
        dispatch(getJobsSuccess(jobsResponse.jobs))
        const remainingJobs = await getJobsByDateAll(
          {
            createdDate: created,
            endDate: end,
            lastKeys: jobsResponse.lastKeys
          },
          true
        )
        dispatch(concatSuccessJob(remainingJobs.jobs))
        dispatch(paginationCompleted(true))
        dispatch(getStatsSuccess(await getStatsNoLoad(created, end, jobsResponse.jobs.concat(remainingJobs.jobs), "", "")))
      } else {
        dispatch(getJobsSuccess(globalJobs))
      }
    } catch (e) {
      dispatch(getJobsFail())
      dispatch(setError(JSON.stringify(e)))
    }
  }

export const FETCH_DATA_JOB = (masterjob: string) => async (dispatch: Dispatch) => {
  dispatch(requestJobs())
  try {
    const job = await getJobByID(masterjob, false)
    dispatch(getJobsSuccess(job.jobs))
  } catch (e) {
    dispatch(setError(JSON.stringify(e)))
  }
}

export const FETCH_DATA_JOB_CHILD = (created: string, end: string) => async (dispatch: Dispatch) => {
  dispatch(requestChildJobs())
  try {
    dispatch(getChildsJobSuccess(await getChilds(created, end)))
  } catch (e) {
    dispatch(errorRequestChildJobs())
    dispatch(setError(JSON.stringify(e)))
  }
}
