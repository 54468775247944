import { ACTIONS } from '../constants/enum'
import { Dispatch } from 'redux'
import { getJobOutput } from '../services/jobsService'
import { setError } from './error'

const getURLdownload = (data: string) => {
  return {
    type: ACTIONS.DOWNLOAD_ZIP,
    payload: data
  }
}

const getError = () => {
  return {
    type: ACTIONS.ERROR_REQUEST_DOWNLOAD
  }
}

const clean = () => {
  return {
    type: ACTIONS.CLEAN_DOWNLOAD,
    payload: ''
  }
}

export const CLEAN_DOWNLOAD = () => async (dispatch: Dispatch) => {
  dispatch(clean())
}

export const DOWNLOAD = (masterjob: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(getURLdownload(await getJobOutput(masterjob)))
  } catch (e) {
    dispatch(getError())
    dispatch(setError(JSON.stringify(e)))
  }
}
