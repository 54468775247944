import { ACTIONS } from '../constants/enum'
import { PageAction, PageSize } from '../constants/interface'

const initialStateSize = {
  pageSize: 50,
  loadComplete: true
}

const initialStatePageRowTable = {
  pageRow: 50
}

export const PAGE_SIZE = (state = initialStateSize, action: PageAction) => {
  switch (action.type) {
    case ACTIONS.SET_SIZE_PAGE:
      return {
        ...state,
        pageSize: action.payload
      }
    case ACTIONS.ALL_PAGE_LOAD:
      return {
        ...state,
        loadComplete: action.payload
      }
    default:
      return state
  }
}

export const PAGE_TABLE = (state = initialStatePageRowTable, action: PageSize) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE_TABLE:
      return {
        pageRow: action.payload
      }
    default:
      return state
  }
}
