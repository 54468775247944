import { ACTIONS, TAB_TYPE } from '../constants/enum'
import { TabAction } from '../constants/interface'

const initialState = {
  tab: TAB_TYPE.Jobs
}

const SET_TAB = (state = initialState, action: TabAction) => {
  switch (action.type) {
    case ACTIONS.SET_COSTS:
      return {
        tab: TAB_TYPE.Costs
      }
    case ACTIONS.SET_JOBS:
      return {
        tab: TAB_TYPE.Jobs
      }
    case ACTIONS.SET_STATS:
      return {
        tab: TAB_TYPE.Stats
      }
    case ACTIONS.SET_CHILD:
      return {
        tab: TAB_TYPE.Childs
      }
    default:
      return state
  }
}

export default SET_TAB
