import { ACTIONS } from '../constants/enum'
import { ErrorAction } from '../constants/interface'

const ERROR = (state = { data: '' }, action: ErrorAction) => {
  switch (action.type) {
    case ACTIONS.SET_ERROR:
      return {
        data: action.payload
      }
    case ACTIONS.CLEAN_ERROR:
      return {
        data: ''
      }
    default:
      return state
  }
}

export default ERROR
