import { combineReducers } from 'redux'
import SET_TAB from './tabs'
import { LIST_JOBS, LOAD_CHILD } from './jobs'
import DOWNLOAD_ZIP from './download'
import { LIST_COSTS } from './costs'
import STATS from './stats'
import { PAGE_SIZE, PAGE_TABLE } from './pages'
import ERROR from './error'
import { SAVE_DATES } from './date'
export default combineReducers({
  SET_TAB,
  LIST_JOBS,
  DOWNLOAD_ZIP,
  LIST_COSTS,
  STATS,
  PAGE_SIZE,
  PAGE_TABLE,
  ERROR,
  SAVE_DATES,
  LOAD_CHILD
})
