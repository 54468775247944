import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
import { SELECT_TAB } from '../actions/selectTab'
import { TAB_TYPE } from '../constants/enum'
import { FormFilters } from './filters'
import { headerHeight, headerTitle } from '../constants/const'
import { Stack } from '@mui/system'
import store from '../store'
import { Alert, Tab, Tabs } from '@mui/material'
interface Props {
  window?: () => Window
  children: React.ReactElement
}

function HideOnScroll(props: Props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

const HeaderDashboard = (props: Props) => {
  const [tab, setTab] = React.useState(TAB_TYPE.Jobs)

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(
      (event.target as HTMLInputElement).firstChild?.nodeValue as TAB_TYPE
    );
    store.dispatch(
      SELECT_TAB(
        (event.target as HTMLInputElement).firstChild?.nodeValue as TAB_TYPE
      )
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar
            style={{
              minHeight: headerHeight,
              maxHeight: headerHeight
            }}
            sx={{alignItems:"center", display:"flex", justifyContent:"center", WebkitJustifyContent:"space-between"}}
          >
            <Typography fontSize='h4.fontSize' align='left' component='div'>
              {headerTitle}
            </Typography>

            <Stack flexDirection={"row"} gap={1}>
              <Tabs value={tab} onChange={changeTab} centered>
                <Tab value={TAB_TYPE.Jobs} label={TAB_TYPE.Jobs} />
                <Tab value={TAB_TYPE.Childs} label={TAB_TYPE.Childs} />
                <Tab value={TAB_TYPE.Stats} label={TAB_TYPE.Stats} />
                <Tab value={TAB_TYPE.Costs} label={TAB_TYPE.Costs} />
              </Tabs>
              </Stack>
          </Toolbar>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{
              minHeight: headerHeight,
              maxHeight: headerHeight
            }}
          >
            <FormFilters tab={tab}></FormFilters>
          </Box>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  )
}

export default HeaderDashboard
